import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Typography from "../../components/typography"
import LabelIcon from "../../components/labelIcon"
import Maps from "../../components/maps"
import AddressIcon from "../../assets/svgs/address.svg"
import { ADDRESS } from "../../components/footer/constants"

const StyledContactInfo = styled.div`
  @media (max-width: 600px) {
    * {
      font-size: 1.8rem !important;
    }
  }
`

const Contact = props => {
  return (
    <Layout {...props}>
      <SEO
        title="Contato e Localização da GastroCirúrgica em Florianopólis/SC"
        description="Veja a localização, telefone e e-mail de contatos com GastroCirúrgica"
      />
      <Section padding="1.5rem 0 0">
        <Container>
          <StyledContactInfo>
            <a href="mailto:contato@gastrocirurgica.com.br">
              <Typography
                variant="h1"
                component="h1"
                color="secondary"
                removeMargin
              >
                contato@gastrocirurgica.com.br
              </Typography>
            </a>
            <Typography variant="h1" component="h1" color="secondary">
              <a href="tel:4832072050">
                <Typography variant="h1" component="span" color="secondary">
                  (48) 3207-2050
                </Typography>
              </a>
            </Typography>
          </StyledContactInfo>
          <div style={{ margin: "2rem 0" }}>
            <LabelIcon icon={AddressIcon}>
              <Typography variant="bodySmall">Endereço:</Typography>
              <Typography variant="bodyLarge" component="p" pre>
                {ADDRESS}
              </Typography>
            </LabelIcon>
            <LabelIcon icon={AddressIcon}>
              <Typography variant="bodySmall">
                Horário de funcionamento:
              </Typography>
              <Typography variant="bodyLarge" removeMargin>
                8h às 20h de segunda-feira a sexta-feira
              </Typography>
            </LabelIcon>
          </div>
        </Container>
      </Section>
      <Maps />
    </Layout>
  )
}

export default Contact
