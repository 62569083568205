import React, { memo } from "react"

const Maps = () => (
  <iframe
    title="Map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3535.755578143898!2d-48.54718768494044!3d-27.601106482836116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfc4fb0e48cf4feab!2sBaia%20Sul%20Medical%20Center!5e0!3m2!1spt-BR!2sbr!4v1596409401261!5m2!1spt-BR!2sbr"
    width="100%"
    height="550"
    frameBorder="0"
    style={{ border: 0 }}
    allowFullScreen=""
    aria-hidden="false"
  />
)

export default memo(Maps)
